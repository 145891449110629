<template>
  <div class="header" id="particles">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h1 class="header-title">
            Enabling B2B Product Mobility across Africa
          </h1>
          <p class="header-subtitle">
            Clan helps businesses offer split payment to retailers across africa.
          </p>
          <button @click="$emitter.emit('sign-up')" class="primary-btn cta">
            Get started
          </button>
        </div>
        <div
          class="col-md-6 d-none d-md-flex justify-content-end globe-wrap" data-aos="fade-in" data-aos-easing="ease"
          data-aos-duration="1000"
        >
          <img id="globe" src="@/assets/images/globe.gif" alt=""/>
        </div>
      </div>
      <div class="header-items">
        <div class="header-item">
          <div class="count">1,000,000+</div>
          <div class="label">Products</div>
        </div>
        <div class="header-item">
          <div class="count">1,000+</div>
          <div class="label">Suppliers</div>
        </div>
        <div class="header-item">
          <div class="count">50,000+</div>
          <div class="label">Retailers</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'particles.js/particles';

export default {
  name: 'Header',
  mounted() {
    this.initParticles();
  },
  methods: {
    initParticles() {
      window.particlesJS("particles", {
        particles: {
          number: { value: 80, density: { enable: true, value_area: 800 } },
          color: { value: "#ffffff" },
          shape: {
            type: "circle",
            stroke: { width: 0, color: "#000000" },
            polygon: { nb_sides: 5 },
            image: { src: "img/github.svg", width: 100, height: 100 }
          },
          opacity: {
            value: 0.2,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
          },
          size: {
            value: 3,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.03,
            width: 1
          },
          move: {
            enable: true,
            speed: .2,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: false },
            onclick: { enable: false },
            resize: true
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: { distance: 400, size: 40, duration: 2, opacity: 8, speed: 3 },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true
      });
    },
  }
}
</script>

<style scoped lang="scss">
.header {
  padding: 10rem 0 20rem;
  min-height: 100vh;
  z-index: 3;
  background: #0E1020;
  color: #eee;
  position: relative;
  width: 100%;
  background: linear-gradient(270deg, #0a142d, #080f23, #081544, #040f2c);
  background-size: 600% 600%;
  overflow: hidden;
  animation: AnimationName 15s ease infinite;

  @media (max-width: 1100px) {
    padding-bottom: 5rem;
  }

  .container {
    position: relative;
    z-index: 1000;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0 50%
  }
}

.header #globe {
  width: 90%;
  animation: globe-animation 5s ease-in-out alternate-reverse infinite;
  filter: brightness(0.8);
}

@keyframes globe-animation {
  0% {
    transform: translateY(0px);

  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}

.header .globe-text {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 1.5rem;
  border-radius: 10px;
  background: rgba(255, 255, 255, .8);
  backdrop-filter: blur(2px);
  color: #0E1020;
  font-weight: 500;
  animation: globe-animation 4s ease-in-out alternate-reverse infinite;
}

.header .wrap {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.header .caption {
  color: #3864e7;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.header-title {
  font-size: 4.4rem;
  font-weight: 900;
  font-family: "Nunito Sans", sans-serif !important;
  line-height: 4.8rem;

  .typed {
    font-family: "Nunito Sans", sans-serif !important;
  }

  @media (max-width: 1100px) {
    font-size: 3.3rem;
    line-height: 4rem;
  }
}

.header-subtitle {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-top: 2rem;
  max-width: 500px;
}

.header-items {
  width: min-content;
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem 5rem;
  z-index: 2;
  position: relative;

  @media (max-width: 1100px) {
    margin-top: 5rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
}

.header-item .count {
  font-size: 2.2rem;
  font-weight: 800;
  line-height: 1;
  color: #e26641;
}

.header-item .label {
  margin-top: 10px;
  opacity: 0.7;
  font-size: 1rem;
  white-space: nowrap;
}

:deep(.typed-cursor) {
  color: #FB8627;
}

.primary-btn {
  background: darken(#FB8627, 10);
  color: #fff;

  &:hover {
    background: darken(#FB8627, 20);
  }
}
</style>
