<template>
  <div class="products-wrap">
    <div class="inner-wrap">
      <button @click="$emit('close')" class="products-close"><i class="ri-close-line"></i></button>
      <div class="title">Get started</div>
      <p class="subtitle">Africa's biggest and most penetrative buy-now-pay-later platform</p>
      <hr class="my-4"/>
      <div class="items">
        <a v-for="item in items" :key="item.link" :href="item.link" target="_blank" class="item" :class="item.color">
          <i class="icon" :class="item.icon"></i>
          <div class="name">
            {{ item.name }}
          </div>
        </a>
      </div>
      <a href="https://sell.clan.africa" class="btn btn-primary w-100 mt-4 rounded-pill">
        Get started
      </a>
    </div>
    <div class="info">
      <h5 class="title">Get in touch</h5>
      <ul class="items">
        <li class="item">
          <a href="tel:+23409155577731">
            <img src="/images/phone-call.svg" class="footer-icon" alt=""/>
            +2349155577731
          </a>
        </li>
        <li class="item">
          <a href="mailto:support@clan.africa">
            <img src="/images/send.svg" class="footer-icon" alt="">
            support@clan.africa
          </a>
        </li>
        <li class="item">
          <img src="/images/address.svg" class="footer-icon" alt=""/>
          77, Samuel Adedoyin street, Victoria Island, Lagos.
        </li>
        <li class="social">
          <a href="https://www.facebook.com/clanafricahq" target="_blank" class="social-wrapper facebook">
            <img src="/images/facebook.svg" alt=""/>
          </a>
          <a href="https://instagram.com/clanafricahq" target="_blank" class="social-wrapper instagram">
            <img src="/images/instagram.svg" alt=""/>
          </a>
          <a href="https://www.linkedin.com/company/clanafrica/" target="_blank" class="social-wrapper linkedin">
            <img src="/images/linkedin.svg" alt=""/>
          </a>
          <a href="https://twitter.com/clanafricahq" class="social-wrapper twitter">
            <img src="/images/twitter.svg" alt=""/>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsSignUp",
  props: {
    visible: {
      type: Boolean,
      required: true,
    }
  },
  watch: {
    visible(v) {
      if (v) document.scrollingElement.style.overflow = 'hidden';
      else document.scrollingElement.style.overflowY = 'auto';
    }
  },
  data() {
    return {
      items: [
        {
          color: 'green',
          icon: 'fa-solid fa-shop',
          name: 'Supermarket',
        },
        {
          color: 'red',
          icon: 'fa-solid fa-suitcase-medical',
          name: 'Health & Beauty',
        },
        {
          color: 'blue',
          icon: 'fa-solid fa-gamepad',
          name: 'Gaming',
        },
        {
          color: 'yellow',
          icon: 'fa-solid fa-tablet-screen-button',
          name: 'Phone & Tables',
        },
        {
          color: 'blue',
          icon: 'fa-solid fa-vest-patches',
          name: 'Fashion',
        },
        {
          color: 'red',
          icon: 'fa-solid fa-computer',
          name: 'Computing',
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.products-wrap {
  height: 100%;
  overflow-y: auto;
}

.products-close {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.5rem;
  border: 1px solid #c2334d;
  color: #c2334d;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: all .2s ease-in-out;
  background: none;

  &:hover {
    background: #c2334d;
    color: #fff;
  }
}

.inner-wrap {
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  padding: 2.5rem;
  flex-grow: 1;

  .title {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #2d58ba;
  }

  .subtitle {
    font-size: 1rem;
  }

  .items {
    background: #fff;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    .item {
      padding: 1rem 0;
      text-align: center;
      border-radius: 12px;
      transition: all .2s ease-in-out;
      display: flex;
      flex-direction: column;

      .icon {
        font-size: 1.5rem;
        color: #bebebe;
      }

      .name {
        font-size: .9rem;
        opacity: .9;
        margin-top: 10px;
      }

      &.red {
        .icon {
          color: #dc2743;
        }
      }

      &.blue {
        .icon {
          color: #17449f;
        }
      }

      &.green {
        .icon {
          color: #449f63;
        }
      }

      &.yellow {
        .icon {
          color: #c2ba32;
        }
      }
    }
  }
}


.info {
  padding: 2rem;
  background: #f0f7ff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .item {
    margin-top: .8rem;
    font-size: .9rem;
    display: flex;
    align-items: center;

    .footer-icon {
      width: 15px;
      margin-right: 10px;
    }
  }

  .social {
    margin-top: 1.5rem;
  }
}
</style>
