<template>
  <div class="main">
    <Navbar/>
    <Header/>
    <WhatsappBuy/>
    <Merchant/>
    <!--    <Rent/>-->
    <!--    <Eat/>-->
    <!--    <School/>-->
    <!--    <Care/>-->
    <KeyFocus/>
    <!--    <JoinUs/>-->
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/home/Header";
import KeyFocus from "@/components/home/KeyFocus";
import Navbar from "@/components/global/Navbar";
import Footer from "@/components/home/Footer";
import WhatsappBuy from "@/components/home/WhatsappBuy";
import Merchant from "@/components/home/Merchant.vue";

export default {
  name: 'Home',
  components: {
    // JoinUs,
    // Eat,
    // Rent,
    WhatsappBuy,
    // School,
    // Care,
    Footer,
    Navbar,
    KeyFocus,
    Header,
    Merchant,
  },
}
</script>
