<template>
  <div>
    <section class="dotted-bg category-section">
      <div class="container text-left text-md-center">
        <h2 class="section-main-title big-title mx-auto" style="max-width: 900px;">
          Split payment from 5,000+ wholesalers.
        </h2>
        <p class="p">
          Connect with credit stores from Instagram, Jiji, Jumia, Konga, etc.
        </p>
        <br/>
        <br/>
        <br/>
      </div>
      <div class="category">
        <swiper loop :autoplay="{ delay: 0, disableOnInteraction: false }" :breakpoints="{
          0: { slidesPerView: 1.2, spaceBetween: 20 },
          470: { slidesPerView: 2, spaceBetween: 20 },
          760: { slidesPerView: 3, spaceBetween: 20 },
          1200: { slidesPerView: 4, spaceBetween: 20 },
        }" :space-between="30" :speed="10000">
          <swiper-slide v-for="category in categories" :key="category.id">
            <div>
              <div style="cursor: pointer" class="card">
                <div class="inner-background" :style="{ backgroundImage: `url(${category.image})` }"/>
                <div class="card-text">
                  <h3>{{ category.name }}</h3>
                  <p>1,000+ merchants</p>
                  <div class="cart">
                    <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                  </div>
                  <div class="view-more">
                    <a
                      :href="`https://shop.clan.africa/search?q=${category.keyword}`" target="_blank"
                      class="primary-btn mt-4"
                    >
                      Start shopping
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <section class="ecosystem-lending">
      <div class="container" data-aos="fade-up" data-aos-easing="ease" data-aos-duration="1000">
        <div class="row">
          <div class="col-md-5 pe-md-4 mb-5">
            <h2 class="section-main-title">
              Simplified tech to help manage everything
            </h2>
            <p class="p m-0">
              Connect with your supplier and retailers on our omnichannel
            </p>
            <br>
            <a href="https://ride.clan.africa/" target="_blank" class="primary-btn">
              Start now
            </a>
          </div>
          <div class="col-md-7 ps-md-4 mt-5 mt-md-0 position-relative">
            <img
              src="@/assets/images/dashboard.png" alt="Dashboard mockup" class="rounded-4"
              style="position: absolute; left: 50px; top: 30%;width: 120%"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import categories from "@/lib/categories.js";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Autoplay]);
export default {
  name: "Merchant",
  components: { Swiper, SwiperSlide },
  data() {
    return {
      categories,
    };
  },
};
</script>

<style scoped lang="scss">
.category-section {
  padding: 16rem 0 10rem;
  background: #fff;
  clip-path: polygon(0 7%, 100% 0, 100% 100%, 0 100%);
  margin-top: -70px;
  position: relative;
  z-index: 10;
  overflow: hidden;
  width: 100vw;

  @media (max-width: 1100px) {
    padding: 6rem 0;
    clip-path: none;
    margin: 0;
  }
}

.card {
  height: 400px;
  position: relative;
  overflow: hidden;

  .category-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
  }

  .card-text {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    color: #fff;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.2);
    padding: 2rem 1.5rem;

    h3 {
      font-size: 2rem;
      color: #ffffff;
      font-weight: bold;
      padding-bottom: 0.5rem;
    }

    p {
      font-size: 1.2rem;
    }
  }
}

.cart {
  position: absolute;
  bottom: -15%;
  right: -15%;
  transform: rotate(-30deg);
  opacity: 0.5;
  mix-blend-mode: color-dodge;

  i {
    font-size: 20rem;
    color: #222;
  }
}


.card {
  .inner-background {
    position: absolute;
    inset: 0;
    cursor: pointer;
    background-size: cover;
    transform: scale(1);
    transition: all 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
  }

  .view-more {
    opacity: 0;
    transition: 0.5s ease-in-out;

    a {
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 4px;
      border: 1px solid #fff;
      background: transparent;
      color: #fff;
    }

    &:hover {
      a {
        background: #fff;
        border: 1px solid #fff;
        color: #000;
      }
    }
  }

  &:hover {
    .inner-background {
      transform: scale(1.2);
    }

    .content {
      opacity: 0.9;
    }

    .view-more {
      opacity: 1;
      transform: translateX(5px);
    }
  }
}

.ecosystem-lending {
  padding: 0 0 16rem;
}

.ecosystem-lending-item {
  width: 47%;
}

.ecosystem-lending-item img {
  height: 40px;
  width: 40px;
  margin-top: 20px;
  margin-bottom: 6px;
}

.ecosystem-lending-item span {
  font-weight: 800;
}

.ecosystem-lending-item p {
  padding: 0 !important;
  font-size: 1rem;
  opacity: .8;
  line-height: 1.3rem;
}
</style>
