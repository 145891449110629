<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-content">
        <ul class="footer-list">
          <li class="section-main-title">
            <a href="/">
              <img class="logo-footer" src="/images/clan-light.png" alt=""/>
            </a>
          </li>
          <li class="p bold mt-4">
            We help financial Institutions and businesses provide credit to
            consumers across africa
          </li>
          <li class="p">Mon - Fri: 9:00 - 18:00<br/>Closed on Weekends</li>
        </ul>

        <ul class="footer-list">
          <li class="section-main-title">Products</li>
          <li class="mb-4">
            <a href="https://shop.clan.africa" target="_blank">Shop now pay later</a>
          </li>
          <li class="mb-4">
            <a href="https://eat.clan.africa" target="_blank">Eat now pay later</a>
          </li>
          <li class="mb-4">
            <a href="https://rent.clan.africa" target="_blank">Rent now pay later</a>
          </li>
          <li class="mb-4">
            <a href="https://power.clan.africa" target="_blank">Power now pay later</a>
          </li>
          <li class="mb-4">
            <a href="https://wa.me/2349053535333?text=care" target="_blank">Care now pay later</a>
          </li>
        </ul>

        <ul class="footer-list">
          <li class="section-main-title">Quick links</li>
          <li class="mb-4"><a href="/about">How we work</a></li>
          <li class="mb-4"><a href="/contact">Contact Us</a></li>
          <li class="mb-4"><a href="https://terms.clan.africa" target="_blank">Terms and Conditions</a></li>
          <li class="mb-4"><a href="http://privacy-policy.clan.africa" target="_blank">Privacy Policy</a></li>
          <!--          <li class="mb-4"><a href="/careers">Careers</a></li>-->
        </ul>

        <ul class="footer-list">
          <li class="section-main-title">Contact Us</li>
          <li>
            <a href="tel:+23409155577731" class="mb-4">
              <i class="fa-solid fa-phone me-3"></i>
              +2349155577731
            </a>
          </li>
          <li>
            <a href="mailto:support@clan.africa" class="mb-4">
              <i class="fa-solid fa-envelope me-3"></i>
              support@clan.africa
            </a>
          </li>
          <li class="mb-4">
            <i class="fa-solid fa-location-dot me-3"></i>
            77, Samuel Adedoyin street, Victoria Island, Lagos.
          </li>
          <li class="social">
            <a href="https://www.facebook.com/clanafricahq" target="_blank" class="social-wrapper facebook">
              <img src="/images/facebook.svg" alt=""/>
            </a>
            <a href="https://instagram.com/clanafricahq" target="_blank" class="social-wrapper instagram">
              <img src="/images/instagram.svg" alt=""/>
            </a>
            <a href="https://www.linkedin.com/company/clanafrica/" target="_blank" class="social-wrapper linkedin">
              <img src="/images/linkedin.svg" alt=""/>
            </a>
            <a href="https://twitter.com/clanafricahq" class="social-wrapper twitter">
              <img src="/images/twitter.svg" alt=""/>
            </a>
          </li>
        </ul>
      </div>
      <div class="footer-copy">
        <div class="">
          Copyright &copy; {{ year }} All rights reserved | Clan
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>

<style scoped lang="scss">
.footer {
  background: #010a1d;
  color: #fff !important;
  padding: 7rem 0 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  flex-wrap: wrap;
}

footer .section-main-title {
  font-size: 1.1rem !important;
  font-weight: 500;
  opacity: 1 !important;
  margin-bottom: 2rem;
}

.footer-list {
  width: 100%;
  padding-bottom: 2rem;
}

.footer-list a,
.footer-list li {
  color: inherit;
  opacity: .85;
  font-size: 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.footer-list a:hover {
  opacity: 1;
}

.footer-copy {
  padding: 3rem 0;
  color: inherit;
  opacity: .8;
  font-size: 1rem;
  border-top: 1px solid rgba(255, 255, 255, .2);
}

.logo-footer {
  height: 32px;
}

@media only screen and (min-width: 860px) {
  .footer-list {
    width: 23%;
    margin-bottom: 0;
  }

  .footer-list:first-child {
    width: 30%;
    margin-bottom: 0;
  }
}
</style>
